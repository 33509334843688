import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import FixedImage from "../components/fixedImage";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import useModalState from '../helpers/modalState';
import 'react-image-lightbox/style.css';
import prepareSchemaHours from "../helpers/prepareSchemaHours";

import * as styleSheet from "./index.module.scss"

import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import loadable from '@loadable/component';

const IndexPage = (props) => {
	console.log(props);

	const CountUp = loadable(() => import('react-countup'));
	const Lightbox = loadable(() => import('react-image-lightbox'));
	const Slider = loadable(() => import('react-slick'));
	const ReviewRotator = loadable(() => import('../components/reviewRotator'));
	const [schemaData, setSchemaData] = useState(null);

	const [showAppointmentModal, setShowAppointmentModal] = useModalState();

	function showModal(){
			if( !showAppointmentModal.modalOpen ){
				setShowAppointmentModal({
                    modalOpen: true, 
                    zipcode: showAppointmentModal.zipcode,
                    greyfinchId: showAppointmentModal.greyfinchId,
                    showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                    callCenterModalOpen: showAppointmentModal.callCenterModalOpen
                });
			}
		}

	function handleApptKeyDown(e) {
		if (e.keyCode === 13 || e.keyCode === 32) {
			setShowAppointmentModal({
                modalOpen: true, 
                zipcode: showAppointmentModal.zipcode,
                greyfinchId: showAppointmentModal.greyfinchId,
                showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                callCenterModalOpen: showAppointmentModal.callCenterModalOpen
            });
		}
	}

	const countUpContainerRef = useRef();

	const [countUpStart, setCountUpStart] = useState(1)
	const [currentLightboxImageIndex, setcurrentLightboxImageIndex] = useState()
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
	const [currentLightboxText, setCurrentLightboxText] = useState('')
	const [currentLightboxLocation, setCurrentLightboxLocation] = useState('')
	const [lightboxSmileImages, setLightboxSmileImages] = useState([])
  	const [smileNumbers, setSmileNumbers] = useState([])
	const [haveSmileNumbersGenerated, setHaveSmileNumbersGenerated] = useState(false)

  	function openLightbox(lightboxIndex, titleText, locationText){
		setcurrentLightboxImageIndex(lightboxIndex)
		setLightboxIsOpen(true)
		setCurrentLightboxText(titleText, locationText)
		setCurrentLightboxLocation(locationText)
	}

	function handleLightboxKeyDown(e, lightboxIndex, titleText, locationText){
		if (e.keyCode === 13 || e.keyCode === 32) {
			openLightbox(lightboxIndex, titleText, locationText)
		}
	}
  
	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1.5,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 699,
			},
			{
				breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
				settings: 'unslick'
			}
		]
	};

	const size = useWindowSize();

	// Hook
	function useWindowSize() {
		// Initialize state with undefined width/height so server and client renders match
		// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});

		useEffect(() => {
      		countUpContainerRef.current.addEventListener("sal:in", () => {setCountUpStart(0)}, false)

			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
			// Add event listener
			window.addEventListener("resize", handleResize);

			// Call handler right away so state gets updated with initial window size
			handleResize();

			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}, []); // Empty array ensures that effect is only run on mount

		return windowSize;
	}

	const data = useStaticQuery(graphql`query IndexPageQuery {
  		home: wpPage(isFrontPage: {eq: true}) {
    		seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
    		}
    		ACFHomePage {
      			hero {
					images {
						mobileBackgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 699, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageTablet: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 999, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageDesktop: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
								}
								publicURL
								extension
							}
						}
						backgroundImageXL: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
								}
								publicURL
								extension
							}
						}
					}
        			content {
						headingImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						headingImageMobile {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 450, placeholder: NONE, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						heading
						blurb
						requestAppointmentLinkText
						phoneNumber
       				}
        			callouts {
          				callout1 {
							preHeading
							heading
							blurb
							makeAnAppointmentLinkText
							backgroundImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
									}
									publicURL
									extension
								}
							}
          				}
          				callout2 {
            				heading
            				imAPrefix
            				links {
								link {
									target
									title
									url
								}
           					}
         				}
        			}
      			}
				intro {
					left {
						blurb
						heading
						fieldGroupName
						list {
							heading
							shortParagraph
							link {
								target
								title
								url
							}
						}
					}
					right {
						smilesPageLink {
							target
							title
							url
						}
					}
				}
				featuredServices {
					leftImage {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH, quality: 100)
								}
								publicURL
								extension
							}
						}
					}
        			rightServices {
						featuredServices {
							heading
							makeHeadingALink {
								title
								target
								url
							}
							blurb
						}
       			 	}
      			}
      			seeYourSmileSection {
					left {
						qrCodeImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 140,layout: FIXED)
								}
								publicURL
								extension
							}
						}
						heading
						steps {
							step
						}
						link {
							target
							title
							url
						}
						mobileInstructions
						mobileLink {
							target
							title
							url
						}
					}
					right {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 700,layout: FULL_WIDTH)
								}
								publicURL
								extension
							}
						}
					}
     			}
      			insuranceSection {
					leftText {
						title
						blurb
					}
					rightInsuranceLogos {
						insurances {
							... on WpInsurance {
								id
								title
								ACFInsurance {
									logo {
										altText
										id
										localFile {
											childImageSharp {
												gatsbyImageData(width: 90, layout: CONSTRAINED)
											}
											publicURL
											extension
										}
									}
								}
							}
						}
					}
      			}
      			smileStats {
					left {
						backgroundImageMobile: backgroundImage {
							altText
							localFile {
							childImageSharp {
								gatsbyImageData(width: 699, layout: CONSTRAINED)
							}
							publicURL
							extension
							}
						}
						backgroundImageTablet: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 999, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageDesktop: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 595, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						backgroundImageXL: backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 850, layout: CONSTRAINED)
								}
								publicURL
								extension
							}
						}
						heading
						paragraphBlurb
						link {
							target
							title
							url
						}
					}
					right {
						stat {
							number {
								prefix
								number
								suffix
								tagline 
								description
							}
						}
					}
      			}
				blogAndSocial {
					blog {
						selectedBlogPosts {
							... on WpPost {
								date(formatString: "MMMM D, Y")
								id
								excerpt
								title
								link
								featuredImage {
									node {
									  localFile {
										childImageSharp {
											gatsbyImageData
										}
									  }
									}
								}
							}
						}
						social {
							heading
							blurb
							fbLink
							igLink
						}
					}
				}
				reviews {
					right {
						heading
						selectedReviews {
							... on WpReview {
								id
								ACFReview {
									blurb
									location
									stars
								}
								title
							}
						}
					}
					backgroundImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
							publicURL
							extension
						}
					}
      			}
   		 	}
  		}
		smileGallery: allWpSmile (limit: 50) {
			nodes {
				id
				smiles {
					smileImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(width: 799)
							}
						}
					}
				}
			}	
		}
		site: site {
			siteMetadata {
				title
			}
		}
  		options: wp {
    		headerSection {
      			ACFHeaderOptions {
        			mainNavigationSettings {
          				headerLogo {
            				altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
								}
								publicURL
								extension
							}
          				}
						mobileNavigationLogo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
								}
								publicURL
								extension
							}
						}
        			}
     		 	}
    		}
  		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		rightArrowGreenOffsite: file(name: {eq: "arrow_green_offsite"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		rightArrowGreen: file(name: {eq: "arrow_green"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		rightArrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
				gatsbyImageData(width: 9, layout: FIXED)
			}
		}
		redStar: file(name: {eq: "Star"}) {
			childImageSharp {
				gatsbyImageData(width: 20, layout: FIXED)
			}
		}
		fbIconWhite: file(name: {eq: "facebook-white"}) {
			childImageSharp {
				gatsbyImageData(width: 20, layout: FIXED)
			}
		}
		igIconWhite: file(name: {eq: "instagram-white"}) {
			childImageSharp {
				gatsbyImageData(width: 20, layout: FIXED)
			}
		}
  	locations: allWpLocation(sort: {fields: title, order: ASC}) {
			nodes {
				birdeyeLocation {
					businessId
					timezone
					location {
						address1
						address2
						city
						state
						zip
						lat
						lng
					}
					name
					phone
					hoursOfOperations {
						day
						isOpen
						workingHours {
							startHour
							endHour
						}
					}
				}
			}
		}
}
`);

	const {
		home,
		home: {
			ACFHomePage: {
				hero: {
					images: {
						mobileBackgroundImage: heroBGMobile,
						backgroundImageTablet: heroBGTablet,
						backgroundImageDesktop: heroBGDesktop,
						backgroundImageXL: heroBGXL,
					},
					content: {
						heading: heroHeading,
						blurb,
						requestAppointmentLinkText,
						phoneNumber
					},
					callouts: {
						callout1: {
							preHeading: c1preHeading,
							heading: c1heading,
							blurb: c1blurb,
							makeAnAppointmentLinkText,
							backgroundImage: c1backgroundImage
						},
						callout2: {
							heading: c2heading,
							imAPrefix: c2imAPrefix,
							links: c2links,
						}
					}
				},
        featuredServices: {
          leftImage: {
            image: servicesImage
          },
          rightServices: {
            featuredServices
          }
        },
        seeYourSmileSection: {
          left: {
            qrCodeImage,
            heading: seeSmileHeading,
            steps: seeSmileSteps,
            link: seeSmileGalleryLink,
            mobileInstructions: seeSmileMobileText,
            mobileLink: seeSmilesMobileLink
          },
          right: {
            image: seeSmilePhoneScreenshot
          }
        },
				intro: {
					left: {
						blurb: introLeftBlurb,
						heading: introLeftHeading,
						list: introLeftList
					},
          right: {
            smilesPageLink
          }
				},
        insuranceSection: {
          leftText: {
            title: insurancesTitle,
            blurb: insurancesBlurb,
          },
          rightInsuranceLogos: {
            insurances
          }
        },
        smileStats: {
					left: {
					  backgroundImageMobile: statsBackgroundImageMobile, 
					  backgroundImageTablet: statsBackgroundImageTablet,
					  backgroundImageDesktop: statsBackgroundImageDesktop,
					  backgroundImageXL: statsBackgroundImageXL,
					  heading: statsHeading,
					  paragraphBlurb: statsParagraph,
					  link: statsLink 
				  	},
					right: {
            stat: stats
          }
				},
				blogAndSocial: {
					blog: {
					  	selectedBlogPosts,
						social: {
							heading: socialHeading,
							blurb: socialBlurb,
							fbLink: socialFbLink,
							igLink: socialIgLink
						}
					}
				},
				reviews: {
					right: {
						heading: reviewsHeading,
						selectedReviews
					},
					backgroundImage: reviewsBackgroundImage 
				},
			},
		},
		options: {
			headerSection: {
				ACFHeaderOptions: {
					mainNavigationSettings: {
						headerLogo,
            mobileNavigationLogo
					}
				}
			}
		},
    smileGallery: smiles,
    site: {
      siteMetadata: {
        title
      }
    },
		rightArrowGreen,
		rightArrowGreenOffsite,
		rightArrowYellow,
		rightArrowBlue,
		redStar,
    fbIconWhite,
		igIconWhite,
		locations: {
			nodes: locationNodes
		}
	} = data;

  function generateRandomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included 
  }
  const numberOfSmiles = smiles.nodes.length
  let smileNumbersToShow = []

  if(!haveSmileNumbersGenerated) {
	  const maxSmiles = numberOfSmiles < 7 ? numberOfSmiles : 7;
    while(smileNumbersToShow.length < maxSmiles){
      var randomNumber = generateRandomNumber(0, numberOfSmiles - 1);
      if(smileNumbersToShow.indexOf(randomNumber) === -1) smileNumbersToShow.push(randomNumber);
    }
    setSmileNumbers(smileNumbersToShow)
    setHaveSmileNumbersGenerated(true)
  }

	smileNumbersToShow.forEach(number => {
		if(smiles.nodes[number].smiles.smileImage?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
      setLightboxSmileImages(oldLightboxSmileImages => [...oldLightboxSmileImages, smiles.nodes[number].smiles.smileImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src]);
    }
	});

	useEffect(() => {
		if (locationNodes.length === 1) {
			const location = locationNodes[0];
			const birdeyeLocation = location.birdeyeLocation;
			const locHours = prepareSchemaHours(birdeyeLocation.hoursOfOperations);

			const newSchema = `{
				"@context": "https://schema.org",
				"@type": "Dentist",
				"name": "${birdeyeLocation.name}",
				"url": "${props.location.href}",
				"telephone": "${birdeyeLocation.phone}",
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "${birdeyeLocation.location.address1} ${birdeyeLocation.location.address2}",
					"addressLocality": "${birdeyeLocation.location.city}",
					"addressRegion": "${birdeyeLocation.location.state}",
					"postalCode": "${birdeyeLocation.location.zip}",
					"addressCountry": "US"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": ${birdeyeLocation.location.lat},
					"longitude": ${birdeyeLocation.location.lng}
				},
				"openingHours": [${locHours}]
			}`;
	
			setSchemaData(newSchema);
		}
	}, []);

	return (
    <Layout hideSmilesSection={true} isHomepage={true}>
			<Seo post={home} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
			<section className={styleSheet.hero}>
				<div className={styleSheet.top}>
					<div className={styleSheet.herobgcolor}></div>
					<div className={styleSheet.herobgcolormoremobile}></div>
					{ heroBGMobile && <FluidImage image={heroBGMobile} passedClass={styleSheet.mobilebg} loading="eager" /> }
					{ heroBGTablet && <FluidImage image={heroBGTablet} passedClass={styleSheet.bgtablet} loading="eager" /> }
					{ heroBGDesktop && <FluidImage image={heroBGDesktop} passedClass={styleSheet.bgdesktop} loading="eager" /> }
					{ heroBGXL && <FluidImage image={heroBGXL} passedClass={styleSheet.bgxl} loading="eager" /> }
					<div className={styleSheet.content}>
						{ headerLogo && <FluidImage image={headerLogo} passedClass={styleSheet.headerlogo} loading={"eager"} /> }
						{ mobileNavigationLogo && <FluidImage image={mobileNavigationLogo} passedClass={styleSheet.headerlogomobile} loading={"eager"} /> }

            { title && <h1 className={styleSheet.sronly}>{title}</h1>}
						{ heroHeading && <h2 className={styleSheet.heroheading} dangerouslySetInnerHTML={{__html: heroHeading}}></h2> }
						{ blurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: blurb }}></div> }
						{ requestAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className="bookappointmentbtn" aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText} <FixedImage image={rightArrowGreen} passedClass="icon" /></button> } 

						{ phoneNumber && <a className={styleSheet.phone} href={`tel:${phoneNumber}`}>{phoneNumber} <GatsbyImage
                              image={rightArrowGreenOffsite.childImageSharp.gatsbyImageData}
                              className={styleSheet.icon}
                              placeholder="none"
                              alt='' 
                              /></a> }
					</div>
				</div>
				<div className={styleSheet.bottom}>
					<div className={styleSheet.callout1}  data-sal={size.width < 700 ? 'none' : 'slide-up'} data-sal-delay={size.width < 700 ? '600' : '1200'} data-sal-easing="ease-in">
						{ c1preHeading && (
							<div className={styleSheet.preheadcontainer}>
								<span className={styleSheet.preheading}>{c1preHeading}</span> 
							</div>
						)}
						{ c1heading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: c1heading}}></div> }
						{ c1blurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: c1blurb}}></div> }

            {makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText}</button>}

						{ c1backgroundImage && <FluidImage image={c1backgroundImage} passedClass={styleSheet.bg} loading={"eager"} /> }
					</div>
					<div className={styleSheet.callout2} data-sal="slide-up" data-sal-delay="800" data-sal-easing="ease-in">
						{ c2heading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: c2heading}}></div> }
            <nav className={styleSheet.patientdirections}>
              { c2imAPrefix && <span className={styleSheet.prefix}>{c2imAPrefix}</span> }
              { c2links && c2links.map( (link, index) => {
                if(link.link.url.includes('schedule-online')) {
                return <button key={`ctalinks-${index}`} onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{link.link.title} <FixedImage image={rightArrowGreen} passedClass={styleSheet.icon} /></button> 
              } else {
                return <WPLink key={`ctalinks-${index}`} link={link.link} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} icon={rightArrowGreen} />;
              }
              })}
            </nav>
					</div>
				</div>
			</section>

			<section className={styleSheet.intro}>
				<div className={styleSheet.left}>
					{ introLeftHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introLeftHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ introLeftBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introLeftBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introLeftList && (
						<ul className={styleSheet.list}>
							{ introLeftList.map( (listitem, _index) => {
								return (
									<li key={`${listitem.heading}-${_index}`} className={styleSheet.listitem} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
										<FixedImage image={rightArrowBlue} passedClass={styleSheet.icon} />
										{ listitem.heading && <span className={styleSheet.listheading} dangerouslySetInnerHTML={{__html: listitem.heading }}></span> }
										{ listitem.shortParagraph && <p className={styleSheet.paragraph} dangerouslySetInnerHTML={{__html: listitem.shortParagraph}}></p> }
										{ listitem.link && <WPLink link={listitem.link} passedClass={styleSheet.link} icon={rightArrowGreenOffsite} passedIconClass={styleSheet.linkicon} /> }
									</li>
								)
							})}
						</ul>
					)}
				</div>

        <div className={styleSheet.right}>
          {smiles?.nodes && (<ul className={styleSheet.smileslist}>
              <Slider {...sliderSettings}>

              {smileNumbers && smiles.nodes && smileNumbers.map((smileNumber, _index) => {
                const smileID = smiles.nodes[smileNumber].id,
                smilePost = smiles.nodes[smileNumber].smiles;

                return (
                   <li key={smileID} className={styleSheet.smileitem}>
                      <button className={styleSheet.lightboxtarget} onKeyDown={(e) => handleLightboxKeyDown(e, _index, "", "")} onClick={() => openLightbox(_index, "", "")}>
                        {smilePost.smileImage && <FluidImage image={smilePost.smileImage} passedClass={styleSheet.smileimage} loading={"lazy"} />} 
                      </button>
                    </li>
                  )
              })}
              </Slider>
          </ul>)}

          {smilesPageLink && <div className={styleSheet.smilesLinkWrap} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"><WPLink link={smilesPageLink} passedClass={styleSheet.smilesPageLink} icon={rightArrowGreen} passedIconClass={styleSheet.linkicon} /></div>}

		  {lightboxIsOpen && (
            <Lightbox 
              mainSrc={lightboxSmileImages[currentLightboxImageIndex]} 
              onCloseRequest={() => setLightboxIsOpen(false)} 
              imageTitle={currentLightboxLocation}
              imageCaption={currentLightboxText}
              enableZoom={false}
            />
          )}
        </div>
			</section>

      <div className={styleSheet.servicesandsmilesectionwrap}>
        {featuredServices && <section className={styleSheet.featuredservices}>
          <div className={styleSheet.leftimage}>
            <FluidImage image={servicesImage} passedClass={styleSheet.servicesimage} loading="lazy" />
          </div>
          <div className={styleSheet.rightservices}>
            <ul className={styleSheet.serviceslist}>
              {featuredServices.map((ftService, _index) => {
                return (
                  <li key={'ftService-'+_index} className={styleSheet.ftservice}>
                    {ftService.makeHeadingALink && ftService.heading && <h2 className={styleSheet.title}><a href={ftService.makeHeadingALink.url} target={ftService.makeHeadingALink.target}>{ftService.heading}</a></h2>}
                    {!ftService.makeHeadingALink && ftService.heading && <h2 className={styleSheet.title}>{ftService.heading}</h2>}
                    {ftService.blurb && <p className={styleSheet.blurb}>{ftService.blurb}</p>}
                  </li>
                )}
              )}
            </ul>
          </div>
        </section>}


      {/** See your smile */}
      <section className={styleSheet.seeyoursmile}>
        <div className={styleSheet.contentwrap}>
            <div className={styleSheet.left}>
                  {seeSmileHeading && <h2 className={styleSheet.heading}>{seeSmileHeading}</h2>}
                  {seeSmileMobileText ? <p className={styleSheet.mobileinstructions}>{seeSmileMobileText}</p>: 'oh boy'}
                  <div className={styleSheet.stepsandcode}>
                    {seeSmileSteps && <ol className={styleSheet.smilesteps}>
                      {seeSmileSteps.map((step, _index) => {
                        const stepText = step.step
                        return <li key={'step-' + _index}>{stepText}</li>
                      })}
                    </ol>}

                    {qrCodeImage && (
                      <div className={styleSheet.scancode}>
                        <p className={styleSheet.scantext}>Scan the QR Code</p>
                        <FixedImage image={qrCodeImage} alt={qrCodeImage.altText} />
                      </div>
                    )}
                  </div>

                  {seeSmileGalleryLink && <div className={styleSheet.linkwrapdesktop}><WPLink link={seeSmileGalleryLink} passedClass={styleSheet.link} icon={rightArrowGreen} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /></div>}
                  {seeSmilesMobileLink && <p className={`${styleSheet.linkwrapmobileonly} mobile-only`}><WPLink link={seeSmilesMobileLink} passedClass={`${styleSheet.linkmobile} linkmobilesmilesgallery`} /></p>}

            </div>
            <div className={styleSheet.right}>
              <div className={styleSheet.seesmilephoneimagewrap}>
                {seeSmilePhoneScreenshot && <FluidImage image={seeSmilePhoneScreenshot} passedClass={styleSheet.seesmilephoneimage} loading="lazy" />}
              </div>
            </div>
        </div>
      </section>

      </div>


			{ insurances && (
				<section className={styleSheet.insurance} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" >
          <div className={styleSheet.left}>
            <div>
              {insurancesTitle && <h2 className={styleSheet.title}>{insurancesTitle}</h2>}
              {insurancesBlurb && <p>{insurancesBlurb}</p>}
            </div>
          </div>
          <div className={styleSheet.right}>
            { insurances.map( (insurance, _index) => {
              return <FluidImage key={`insurancelogo-${insurance.ACFInsurance.logo.id}`} passedClass={styleSheet.logo} image={insurance.ACFInsurance.logo} />;
				  	})}
          </div>
				</section> 
			)}

      <section className={styleSheet.statscallout}>
				<div className={styleSheet.left}>
					{ statsBackgroundImageMobile && <FluidImage image={statsBackgroundImageMobile} passedClass={styleSheet.bgmobile} /> }
					{ statsBackgroundImageTablet && <FluidImage image={statsBackgroundImageTablet} passedClass={styleSheet.bgtablet} /> }
					{ statsBackgroundImageDesktop && <FluidImage image={statsBackgroundImageDesktop} passedClass={styleSheet.bgdesktop} /> }
					{ statsBackgroundImageXL && <FluidImage image={statsBackgroundImageXL} passedClass={styleSheet.bgxl} /> }

					{ statsHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: statsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ statsParagraph && <p dangerouslySetInnerHTML={{__html: statsParagraph}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></p> }

					{ statsLink && !statsLink.url.includes('schedule-online') && <WPLink link={statsLink} passedClass={styleSheet.link} icon={rightArrowYellow} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />}
          { statsLink && statsLink.url.includes('schedule-online') &&  <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{statsLink.title} <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button>}
				</div>
				<div className={styleSheet.right}>
					{ stats && (
						<div className={styleSheet.statscontainer}>
							{ stats && stats.map((statGroup, _index) => {
								return (
                  <div key={`smilestate-'${_index}`} className={styleSheet.featuredstat} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" ref={_index === 0 ? countUpContainerRef : null}>
                    <p className={styleSheet.numbergroup}>
                      {statGroup.number?.prefix && <span className={styleSheet.numberprefix}>{`${statGroup.number.prefix} `}</span>}
                      <CountUp 
                          start={countUpStart}
                          end={statGroup.number.number}
                          duration={3.75}
                          separator=","
                          decimals={0}
                          decimal=","
                          delay={0}
                        >
                          {({ countUpRef, start }) => (
                              <span className={styleSheet.countupnumber} ref={countUpRef} />
                          )}
                        </CountUp>
                      {statGroup.number?.suffix && <span className={styleSheet.numbersuffix}>{` ${statGroup.number.suffix}`}</span>}
                      {statGroup?.number?.tagline && <span className={styleSheet.title}>{statGroup.number.tagline}</span>}
                    </p>
                    { statGroup?.number?.description && <p>{statGroup.number.description}</p>}
                  </div>
								)
							})}
						</div>
					)}
				</div>
			</section>



			<section className={styleSheet.bloglocations}>
				<div className={styleSheet.wrap}>
					{ socialHeading && <h2 className={styleSheet.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{socialHeading}</h2> }
					{ socialBlurb && <div className={styleSheet.socialblurb} dangerouslySetInnerHTML={{__html: socialBlurb}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }
					{(socialFbLink || socialIgLink) && (
						<div className={styleSheet.socialmedia} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							{socialFbLink && <a href={socialFbLink} target="_blank" rel="noreferrer" className={styleSheet.fblink}> <FixedImage image={fbIconWhite} alt={'Facebook'} /> <span className={styleSheet.sronly}>Facebook profile</span></a>}
							{socialIgLink && <a href={socialIgLink} target="_blank" rel="noreferrer" className={styleSheet.iglink}> <FixedImage image={igIconWhite} alt={'Instagram'} /><span className={styleSheet.sronly}>Instagram profile</span></a>}
						</div>
					)}
				</div>
				<div className={styleSheet.posts}>
					{selectedBlogPosts && selectedBlogPosts.map((post, index)=> {
						return (
							<article key={index} className={styleSheet.ftpost} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								{ post.featuredImage?.node?.localFile && (
									<Link className={styleSheet.ftpostImgContainer} to={post.link}>
										<FluidImage passedClass={styleSheet.ftpostimg} image={post.featuredImage.node} />
									</Link>
								)}
								<Link to={post.link} className={styleSheet.titlelink}>
									<h2 className={styleSheet.title}>{post.title}</h2>
								</Link>
								<span className={styleSheet.date}><strong>{post.date}</strong></span>
								<div className={styleSheet.excerpt} dangerouslySetInnerHTML={{__html: post.excerpt}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" />
								<Link to={post.link} className={styleSheet.readmorelink}>Read more <span className={styleSheet.sronly}>about the article</span><FixedImage image={rightArrowGreen} passedClass={styleSheet.icon} /></Link>
							</article>
						)
					})}
				</div>
			</section>
			<section className={styleSheet.reviews}>
				{ reviewsBackgroundImage && <FluidImage image={reviewsBackgroundImage} passedClass={styleSheet.bg} /> }
				<div className={styleSheet.inner}>
					{ reviewsHeading && <h2 className={styleSheet.heading} dangerouslySetInnerHTML={{__html: reviewsHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></h2> }
					{ selectedReviews && <ReviewRotator reviews={selectedReviews} redStar={redStar} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }
				</div>
			</section>
		</Layout>
    );
};

export default IndexPage;
